/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { HoverboardState } from '../types'


const initialState: HoverboardState = {
    userHoverBoards : [],
  };
export const Hoverboard = createSlice({
  name: 'Hoverboard',
  initialState,
  reducers: {
    setHoverboard: (state, action: PayloadAction<HoverboardState>) => {
        state.userHoverBoards = action.payload.userHoverBoards;
    },
  },
})

// Actions
export const { setHoverboard } = Hoverboard.actions

export default Hoverboard.reducer
