import { Web3Provider } from "@ethersproject/providers";
import { InjectedConnector } from "@web3-react/injected-connector";
import getRpcUrl from "utils/getRpcUrl";

import { NetworkConnector } from "./NetworkConnector";

const NETWORK_URL = getRpcUrl();

export const NETWORK_CHAIN_ID: number = parseInt(
  process.env.REACT_APP_CHAIN_ID ?? "1"
);

if (typeof NETWORK_URL === "undefined") {
  throw new Error(
    `REACT_APP_NETWORK_URL must be a defined environment variable`
  );
}

export const network = new NetworkConnector({
  urls: { [NETWORK_CHAIN_ID]: NETWORK_URL },
});

let networkLibrary: Web3Provider | undefined;
export function getNetworkLibrary(): Web3Provider {
  return (networkLibrary =
    networkLibrary ?? new Web3Provider(network.provider as any));
}

export const injected = new InjectedConnector({
  supportedChainIds: [
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    34,
    35,
    38,
    40,
    41,
    42,
    43,
    44,
    45,
    50,
    51,
    52,
    53,
    55,
    56,
    57,
    58,
    59,
    60,
    61,
    62,
    63,
    64,
    65,
    66,
    67,
    68,
    69,
    71,
    74,
    76,
    77,
    78,
    80,
    82,
    83,
    85,
    86,
    88,
    89,
    90,
    91,
    92,
    93,
    95,
    96,
    97,
    99,
    100,
    101,
    102,
    106,
    108,
    110,
    111,
    122,
    123,
    124,
    125,
    126,
    127,
    128,
    137,
    142,
    162,
    163,
    170,
    172,
    186,
    188,
    189,
    199,
    200,
    211,
    222,
    246,
    250,
    256,
    262,
    269,
    288,
    321,
    322,
    336,
    338,
    361,
    363,
    364,
    365,
    369,
    385,
    420,
    499,
    558,
    588,
    595,
    666,
    686,
    707,
    708,
    721,
    777,
    787,
    803,
    820,
    821,
    880,
    888,
    900,
    901,
    902,
    903,
    940,
    977,
    998,
    999,
    1001,
    1007,
    1010,
    1012,
    1022,
    1023,
    1024,
    1028,
    1030,
    1088,
    1139,
    1140,
    1202,
    1213,
    1214,
    1280,
    1284,
    1285,
    1287,
    1288,
    1618,
    1620,
    1657,
    1856,
    1987,
    2020,
    2021,
    2022,
    2025,
    2100,
    2101,
    2559,
    3331,
    3690,
    4002,
    4689,
    4690,
    5197,
    5315,
    5700,
    5851,
    5869,
    6626,
    7878,
    8029,
    8080,
    8217,
    8285,
    8723,
    8724,
    8888,
    8995,
    9000,
    9001,
    9100,
    9527,
    9999,
    10000,
    10001,
    10101,
    11111,
    12051,
    12052,
    16000,
    16001,
    19845,
    24484,
    24734,
    31102,
    31337,
    32659,
    39797,
    42069,
    42161,
    42220,
    42261,
    42262,
    43110,
    43113,
    43114,
    44787,
    47805,
    49797,
    60000,
    60001,
    60002,
    60103,
    62320,
    63000,
    63001,
    70000,
    70001,
    70002,
    70103,
    71393,
    73799,
    78110,
    80001,
    99998,
    99999,
    100000,
    100001,
    100002,
    100003,
    100004,
    100005,
    100006,
    100007,
    100008,
    110000,
    110001,
    110002,
    110003,
    110004,
    110005,
    110006,
    110007,
    110008,
    200625,
    201018,
    201030,
    210309,
    246529,
    246785,
    281121,
    333888,
    333999,
    421611,
    666666,
    888888,
    955305,
    1313114,
    1313500,
    1337702,
    7762959,
    13371337,
    18289463,
    20181205,
    28945486,
    35855456,
    61717561,
    99415706,
    192837465,
    245022926,
    245022934,
    245022940,
    311752642,
    356256156,
    486217935,
    1122334455,
    1313161554,
    1313161555,
    1313161556,
    1666600000,
    1666600001,
    1666600002,
    1666600003,
    1666700000,
    1666700001,
    1666700002,
    1666700003,
    2021121117,
    3125659152,
    4216137055,
    11297108099,
    11297108109,
    197710212030,
    197710212031,
    6022140761023,
  ],
});
