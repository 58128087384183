import { Colors } from "./types";

export const baseColors = {
  failure: "#ED4B9E",
  primary: "#000000",
  primaryBright: "#53DEE9",
  primaryDark: "#000000",
  secondary: "#7645D9",
  success: "#31D0AA",
  warning: "#FFB237",
};

export const brandColors = {
  binance: "#F0B90B",
};

export const lightColors: Colors = {
  ...baseColors,
  ...brandColors,
  background: "#000000",
  backgroundDisabled: "#E9EAEB",
  backgroundAlt: "#FFFFFF",
  text: "#452A7A",
  textDisabled: "#BDC2C4",
  textSubtle: "#8f80ba",
  borderColor: "#E9EAEB",
};

export const darkColors: Colors = {
  ...baseColors,
  ...brandColors,
  secondary: "#9A6AFF",
  background: "#000000",
  backgroundDisabled: "#3c3742",
  backgroundAlt: "#27262c",
  primaryDark: "#0098A1",
  text: "#452A7A",
  textDisabled: "#BDC2C4",
  textSubtle: "#8f80ba",
  borderColor: "#E9EAEB",
};