/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PAGE_STATE_ENUM } from 'utils/enum';
import { PageState } from '../types'


const initialState: PageState = {
    activePage : PAGE_STATE_ENUM.HOVERBOARD,
  };
export const PageStateSlice = createSlice({
  name: 'PageState',
  initialState,
  reducers: {
    setPageState: (state, action: PayloadAction<PageState>) => {
        state.activePage = action.payload?.activePage;
    },
  },
})

// Actions
export const { setPageState } = PageStateSlice.actions

export default PageStateSlice.reducer
