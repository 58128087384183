import { createGlobalStyle } from 'styled-components'


const GlobalStyle = createGlobalStyle`
  * {
    font-family: sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};
    .swiper{
      padding-bottom : 100px !important;
    }
    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
