import { HoverboardState, PageState, State } from './types'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { useCallback } from 'react'
import { setHoverboard } from './hoverboard'
import { setPageState } from './pageState'


export const useFetchPublicData = () => {
} // end of use Fetch Public Data

// Block
export const useBlock = () => {
  return useSelector((state: State) => state.block)
}

export const useInitialBlock = () => {
  return useSelector((state: State) => state.block.initialBlock)
}

export const useSetHoverboardState = () => {
  
  const dispatch = useAppDispatch();
  const setUserHoverBoards = useCallback((data: HoverboardState) => {
    dispatch(setHoverboard(
      {
        userHoverBoards : data?.userHoverBoards,
      }
    ))
    // eslint-disable-next-line
  }, []);
  return { setUserHoverBoards };
};


export const useGetHoverboardState = () => {
  const hoverboardState = useSelector((state: State) =>  state.hoverboard)
  return hoverboardState
}

export const useSetPageState = () => {
  
  const dispatch = useAppDispatch();
  const setPage = useCallback((data: PageState) => {
    dispatch(setPageState(
      {
        activePage : data?.activePage,
      }
    ))
    // eslint-disable-next-line
  }, []);
  return { setPage };
};


export const useGetPageState = () => {
  const pageState = useSelector((state: State) =>  state.pageState)
  return pageState
}