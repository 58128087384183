import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'Forge Jadu Snowboards',
  description:
    'Convert your Jadu Hoverboards (NFTs) into Jadu Snowboards (IRL)',
  image: '%PUBLIC_URL%/logo192.png',
}

export const customMeta: { [key: string]: PageMeta } = {
  '/': {
    title: 'Forge Jadu Snowboards',
  }
}
