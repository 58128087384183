import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 40 40" {...props}>
        <path d="M20 0C9 0 0 9 0 20C0 31 9 40 20 40C31 40 40 31 40 20C40 9 31 0 20 0ZM28.4 16.6L18.8 26.2C18 27 16.8 27 16 26.2L11.6 21.8C10.8 21 10.8 19.8 11.6 19C12.4 18.2 13.6 18.2 14.4 19L17.4 22L25.6 13.8C26.4 13 27.6 13 28.4 13.8C29.2 14.6 29.2 15.8 28.4 16.6Z" fill="white"/>
    </Svg>
  );
};

export default Icon;
